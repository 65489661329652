<template>
  <application-client-info-card :id="id" :redirect="redirect" />
</template>

<script>
import ApplicationClientInfoCard from '@/views/applications/components/ApplicationClientInfoCard'

export default {
  name: 'ClientCreate',
  components: {
    ApplicationClientInfoCard,
  },
  data() {
    return {
      id: this.$route.params.id,
    }
  },
  methods: {
    redirect() {
      this.$router.back()
    },
  },
}
</script>
